<template>
  <div class="nsPages">
    <data-info
      class="dataInfo"
      :loopDetail="loopDetail"
      @changeTime="changeTime"
      :trend="trend"
      :lastSugar="lastSugar"
      :leftSugar="leftSugar"
      :leftTime="leftTime"
      :lastTime="lastTime"
      :lastTemp="lastTemp"
      :lastType="lastType"
    />
    <test
      class="test"
      ref="test"
      @loopInfo="loopInfo"
      :btnNum="btnNum"
      @getTrend="getTrend"
      @getTemp="getTemp"
    />
  </div>
</template>

<script>
import DataInfo from "@/pages/nsPages/components/dataInfo";
import DataChart from "@/pages/nsPages/components/dataChart";
import DetailChart from "@/pages/nsPages/components/detailChart";
import test from "@/pages/bloodSugar/test.vue";
export default {
  name: "nsPages",
  components: { DataInfo, DataChart, DetailChart, test },
  data() {
    return {
      leftSugar: "",
      lastSugar: "",
      trend: "",
      leftTime: "",
      lastTime: "",
      loopDetail: null,
      btnNum: null,
      lastTemp: "",
      lastType: "",
    };
  },
  methods: {
    loopInfo(info) {
      this.loopDetail = info.data;
    },
    changeTime(num) {
      this.btnNum = num;
    },
    getTrend(val1, val2, val3, val4) {
      this.trend = val1;
      this.lastSugar = val2;
      this.leftSugar = val3 >= 0 ? "+" + val3 : val3;
      this.lastTime = val4.substring(11, 16);
      this.leftTime = (
        (this.$moment().valueOf() - this.$moment(val4).valueOf()) /
        1000 /
        60
      ).toFixed(0);
    },
    getTemp(val1, val2) {
      this.lastTemp = val1;
      this.lastType = val2;
    },
  },
};
</script>

<style scoped lang="less">
.nsPages {
  /* 阻止屏幕拖动 */
  overflow-x: hidden;
  /* overflow-y: scroll; */
  /* touch-action: none; */
  .test {
    margin-top: -10px;
  }
}
</style>
