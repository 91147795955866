import Vue from "vue";
import Vuex from "vuex";
import websocket from "./websocket.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baibanId: "",
    isLandscape: false,
  },
  getters: {},
  mutations: {
    setBaibanId(state, val) {
      state.baibanId = val;
    },
    setLandscape(state, val) {
      state.isLandscape = val == 1 ? true : false;
    },
  },
  actions: {},
  modules: {
    websocket,
  },
});
