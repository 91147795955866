<template>
  <!-- <div class="dataInfo"> -->
  <!-- <span>{{ name }}</span>
    <span>
      {{ loopInfo.loop ? loopInfo.loop.name : "-" }}：{{
        loopInfo.create_time ? loopInfo.create_time : "-"
      }}更新
    </span>
    <span>电量：{{ loopInfo.uploader ? loopInfo.uploader.battery : "-" }}</span>
    <span>
      胰岛素泵： {{ loopInfo.pump ? loopInfo.pump.reservoir : "-" }}U
      {{ loopInfo.pump ? loopInfo.pump.battery.percent : "-" }}%
      {{ loopInfo.device ? loopInfo.device : "" }}
      {{ loopInfo.pump ? loopInfo.pump.manufacturer : "-" }}
      {{ loopInfo.pump ? loopInfo.pump.model : "-" }}
    </span>
    <span>
      活性胰岛素IOB：{{
        loopInfo.loop ? loopInfo.loop.iob.iob.toFixed(2) : "-"
      }}U
    </span>
    <span>
      活性碳水COB：{{ loopInfo.loop ? loopInfo.loop.cob.cob.toFixed(2) : "-" }}g
    </span> -->
  <!-- 基础率 -->
  <!-- <el-popover
      placement="bottom"
      width="80"
      trigger="hover"
      popper-class="popper"
      style="padding: 0; cursor: pointer"
      :append-to-body="false"
    >
      <p v-for="item in tempbasal" :key="item.time">
        {{ item.time }}基础率{{ item.value }}
      </p>
      <span slot="reference" class="hover">基础率</span>
    </el-popover> -->
  <!-- 碳水化合物系数 -->
  <!-- <el-popover
      placement="bottom"
      width="80"
      trigger="hover"
      popper-class="popper"
      style="padding: 0; cursor: pointer"
      :append-to-body="false"
    >
      <p v-for="item in carbratio" :key="item.time">
        {{ item.time }}碳水系数{{ item.value }}
      </p>
      <span slot="reference" class="hover">碳水化合物系数</span>
    </el-popover> -->
  <!-- 胰岛素敏感系数 -->
  <!-- <el-popover
      placement="bottom"
      width="80"
      trigger="hover"
      popper-class="popper"
      style="padding: 0; cursor: pointer"
      :append-to-body="false"
    >
      <p v-for="item in insulin" :key="item.time">
        {{ item.time }}胰岛素系数{{ item.value }}
      </p>
      <span slot="reference" class="hover">胰岛素敏感系数</span>
    </el-popover> -->
  <!-- </div> -->
  <div class="dataInfo">
    <div class="left">
      <div
        style="
          font-size: 18px;
          color: #000;
          text-align: center;
          margin-bottom: 10px;
        "
      >
        <!-- {{ loopInfo.create_time ? loopInfo.shortTime : '---' }} -->
        {{ lastTime ? lastTime : "---" }}
      </div>
      <div class="left-text" :style="phoneType == 'ios'?'padding-left:80px':''">
        <div class="left-top">
          <span>
            {{
              loopInfo.loop && loopInfo.loop.name ? loopInfo.loop.name : "aaps"
            }}
            <img
              src="../../../static/pic/26.png"
              style="margin-right: 5px"
              width="8px"
            />
            <i>{{ loopInfo.create_time ? connectStatus : "-" }}</i>
          </span>
          <span>
            <i v-show="loopRemote == 'loop'">
              {{ loopInfo.uploader ? loopInfo.uploader.battery + "%" : "-" }}
            </i>
            <i v-show="loopRemote == 'aaps'">
              {{ loopInfo.uploaderBattery ? loopInfo.uploaderBattery + "%" : "-" }}
            </i>
            <img
              src="../../../static/pic/28.png"
              style="margin-left: 5px; vertical-align: middle; width: 12px"
            />
          </span>
        </div>
        <span class="left-bottom">
          胰岛素泵
          <i style="margin-left: 5px">
            {{ loopInfo.pump && loopInfo.pump.reservoir ? (loopInfo.pump.reservoir * 1).toFixed(1) : '-'
             }}U
            {{ loopInfo.pump && loopInfo.pump.battery ? loopInfo.pump.battery.percent : "-" }}%
            {{ loopInfo.device ? loopInfo.device : "" }}
            {{ loopInfo.pump && loopInfo.pump.manufacturer ? loopInfo.pump.manufacturer : "-" }}
            {{ loopInfo.pump && loopInfo.pump.model ? loopInfo.pump.model : "-" }}
          </i>
        </span>
      </div>
    </div>
    <div class="middle">
      <button @click="changeBtn(0.875)" :class="{ active: btnNum == 0.875 }">
        3小时
      </button>
      <button @click="changeBtn(0.75)" :class="{ active: btnNum == 0.75 }">
        6小时
      </button>
      <button @click="changeBtn(0.5)" :class="{ active: btnNum == 0.5 }">
        12小时
      </button>
      <button @click="changeBtn(0)" :class="{ active: btnNum == 0 }">
        24小时
      </button>
    </div>
    <div class="right">
      <div
        style="
          font-size: 18px;
          color: #70c95f;
          text-align: center;
          margin-bottom: 10px;
        "
        :style="`${isHight ? 'color: red;' : 'color: green;'}`"
      >
        {{ lastSugar || "-" }}
        <u v-if="trend == 0">→</u>
        <u v-else-if="trend == 1">↗</u>
        <u v-else>↘</u>
      </div>
      <div class="right-text" :style="phoneType == 'ios'?'padding-right:20px':''">
        <div class="right-top" style="white-space: nowrap">
          <span>
            <i style="margin-right: 5px">
              <!-- {{ loopInfo.create_time ? leftMinute : '-' }} -->
              {{ leftTime ? leftTime : "-" }}
            </i>
            分钟前
          </span>
          <!-- <span>
            <i style="margin-right: 5px">{{ leftSugar }}</i>
            mmol/L
          </span> -->
          <span>
            基础率
            <i>{{
              (lastType == "tempBasal" ? "T:" + lastTemp : lastTemp) || "-"
            }}</i>
          </span>
        </div>
        <div class="right-bottom" style="white-space: nowrap">
          <span>
            活性胰岛素IOB
            <i style="margin-left: 5px">
              {{
                loopInfo.loop && loopInfo.loop.iob
                  ? Number(loopInfo.loop.iob.iob).toFixed(2)
                  : "-"
              }}U
            </i>
          </span>
          <span>
            活性碳水COB
            <i v-show="loopRemote == 'loop'"
              >{{
                loopInfo.loop && loopInfo.loop.cob
                  ? Number(loopInfo.loop.cob.cob).toFixed(2)
                  : "0.00"
              }}g</i
            >
            <i v-show="loopRemote == 'aaps'">
              {{
                loopInfo.loop && loopInfo.loop.suggested
                  ? Number(loopInfo.loop.suggested.COB).toFixed(2)
                  : "0.00"
              }}g
            </i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loopDevicestatus } from "@/api/relationship";
export default {
  name: "dataInfo",
  props: {
    loopDetail: {},
    trend: { default: 0 },
    lastSugar: {},
    leftSugar: {},
    leftTime: {},
    lastTime: {},
    lastTemp: {},
    lastType: {},
  },
  data() {
    return {
      name: "",
      isHight: false,
      btnNum: 0.875,
      loopInfo: {},
      isHoverTemp: false,
      isHoverCarbratio: false,
      isHoverInsulin: false,
      connectStatus: "",
      loopRemote: "",
      phoneType:localStorage.getItem("phoneType"),
      looptimer: null, // 5分钟一次的定时器
    };
  },
  mounted(){
    this.looptimer = setInterval(() => {
      this.getLoopStatus();
    }, 5 * 60 * 1000);
    this.$once('hook:beforeDestroy', () => {
        // 页面关闭
        clearInterval(this.looptimer) // 停止
      })
  },
  created() {
    this.loopRemote = JSON.parse(localStorage.getItem("appuser")).loopRemote;
    this.name = JSON.parse(localStorage.getItem("appuser")).name;
    this.getLoopStatus();
  },
  methods: {
    // 切换时间
    changeBtn(num) {
      this.btnNum = num;
      this.$emit("changeTime", num);
    },
    // 获取闭环设备信息
    getLoopStatus() {
      let loopRemote = JSON.parse(localStorage.getItem("appuser")).loopRemote;
      loopDevicestatus({
        uid: JSON.parse(localStorage.getItem("appuser")).id,
        type: loopRemote,
      }).then((res) => {
        if (loopRemote == "loop") {
          this.loopInfo = res.data;
          this.loopInfo.shortTime = this.loopInfo.create_time.substring(11, 16);
        } else if (loopRemote == "aaps") {
          this.loopInfo.loop = res.data.openaps;
          this.loopInfo.device = res.data.device;
          this.loopInfo.pump = res.data.pump;
          this.loopInfo.uploaderBattery = res.data.uploaderBattery;
          this.loopInfo.create_time = res.data.create_time;
          // this.loopInfo.uploader = {
          //   battery: res.data.pump.battery.percent,
          // };
          this.loopInfo.shortTime = this.loopInfo.create_time.substring(11, 16);
        }
        // this.loopInfo = res.data;
        let nowtime = new Date().getTime();
        let sugartime = new Date(this.loopInfo.create_time).getTime();
        let lefttime = nowtime - sugartime;
        this.leftMinute = Math.ceil(lefttime / 1000 / 60);
        this.connectStatus =
          this.leftMinute < 30 ? this.leftMinute + "分钟前" : "已断开";
      });
    },
  },
  watch: {
    loopDetail: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal.Devicestatus) {
            let loopRemote = JSON.parse(
              localStorage.getItem("appuser")
            ).loopRemote;
            this.isHight =
              this.lastSugar > 10 || this.lastSugar <= 3.9 ? true : false;
            if (loopRemote == "loop") {
              this.loopInfo = newVal.Devicestatus;
            } else if (loopRemote == "aaps") {
              this.loopInfo.loop = newVal.Devicestatus.openaps;
              this.loopInfo.device = newVal.Devicestatus.device;
              this.loopInfo.pump = newVal.Devicestatus.pump;
              this.loopInfo.uploaderBattery = newVal.Devicestatus.uploaderBattery;
              this.loopInfo.create_time = newVal.Devicestatus.create_time;
              // this.loopInfo.uploader = {
              //   battery: newVal.Devicestatus.pump.battery.percent,
              // };
            }
            // this.loopInfo = newVal.Devicestatus;
            this.loopInfo.shortTime = newVal.Devicestatus.create_time.substring(
              11,
              16
            );
            let nowtime = new Date().getTime();
            let sugartime = new Date(newVal.Devicestatus.create_time).getTime();
            let lefttime = nowtime - sugartime;
            this.leftMinute = Math.ceil(lefttime / 1000 / 60);
            this.connectStatus =
              this.leftMinute < 30 ? this.leftMinute + "分钟前" : "已断开";
          }
        });
      },
    },
  },
};
</script>

<style scoped lang="less">
i,
u {
  font-style: normal;
  text-decoration: none;
}
.dataInfo {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}
.left {
  position: relative;
  margin-top: 10px;
  width: 300px;
  height: 85px;
  text-align: center;
}
.right {
  position: relative;
  margin-top: 10px;
  width: 300px;
  height: 85px;
  text-align: center;
}
.left-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
}
.right-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: right;
}
.left-bottom {
  white-space: nowrap;
}
span {
  display: inline-block;
  padding: 2px;
  margin: 3px;
  background-color: #cfd4d6;
  color: #000;
  font-size: 10px;
  border-radius: 3px;
  i {
    display: inline-block;
    background-color: #fff;
    font-weight: bold;
    padding: 2px;
    border-radius: 2px;
  }
}
.middle {
  display: flex;
  margin-top: 18px;
  button {
    margin: 10px;
    width: 57px;
    height: 20px;
    background-color: #f3faf7;
    color: #009944;
    border: 1px solid #009944;
    border-radius: 3px;
    font-weight: bold;
    font-size: 10px;
    line-height: 8px;
    cursor: pointer;
  }
}
.active {
  background-color: #009944 !important;
  color: #fff !important;
  border: 0 !important;
}
.hover {
  padding: 5px;
}
.popper {
  width: 100px;
}
.el-popover p {
  text-align: center;
  font-size: 10px;
}
</style>
